.app_title {
  color: #f0f8ff;
  align-items: center;
  justify-content: center;
  font-size: calc(14px + 2vmin);
  font-weight: bold;
  text-align: left;
  margin-left: 12px;
  margin-top: 8px;
}
.app_top_section {
  background-color: #232f3e;
  margin: 18px;
  border-radius: 4px;
  display: none;
}

.app_description {
  color: #f0f8ff;
  align-items: center;
  justify-content: center;
  font-size: calc(2px + 2vmin);
  text-align: left;

  padding: 14px;
}
.app_body {
  background-color: #002f5b;
}

.picc_grid {
  margin: 18px;
}

.PICCComp .buttonBug {
  border-color: #cc293d;
  color: #ffffff;
  background-color: #cc293d;
  background-image: none;
}

.PICCComp .buttonBug:hover {
  background-color: #aa0f22;
  color: white;
  background-image: none;
}

.PICCComp .buttonNewReq {
  border-color: #0078d4;
  color: #ffffff;
  background-color: #0078d4;
  background-image: none;
}

.PICCComp .buttonNewReq:hover {
  background-color: #106ebe;
  color: white;
  background-image: none;
}
.grid_GridToolbar {
  width: 100%;
  margin: 0px;
  text-align: right;
  float: right;
  display: flex;
}
.grid_export {
  margin: 0px;
  text-align: right;
  float: right;
}
.addnew {
  width: 100%;
  text-align: left;
  float: left;
}

.k-animation-container {
  z-index: 10010 !important;
}

.k-form .k-form-legend {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  border-color: #bacdde;
}

fieldset .k-form-field {
  margin: 0 3rem auto 0;
}

.riskManagement .k-form-field {
  margin: 0 3rem auto 0;
}

/*Gilead Branding*/
.k-button-primary,
.k-button.k-primary {
  background-color: rgb(197, 15, 60) !important;
  border-color: rgb(197, 15, 60) !important;
  border: 1px solid rgb(197, 15, 60) !important;
  border-radius: 2px !important;
  font-weight: 400 !important;
  color: rgb(255, 255, 255) !important;
  height: 28px !important;
}

.k-dialog-titlebar {
  background: #061433 !important;
  color: rgb(255, 255, 255) !important;
}
.form-bottom-padding {
  padding-bottom: 16px;
}
.displayNone {
  display: none;
}
.divAttachSection {
  border: 1px solid lightgray;
  vertical-align: top;
  padding-left: 6px;
  background: #fafafa;
  text-align: left;
}
.divAttachSection > input {
  margin: 6px;
}
.divAttachments {
  background: #ffffff;
  margin-bottom: 5px;
  font-size: 12px;
  vertical-align: top;
  padding-left: 6px;

  display: flex;
}
.divAttachments > img {
  width: 24px;
  margin-left: 10px;
  vertical-align: bottom;
  margin-top: 2px;
}
.divAttachments > div {
  margin: 0px 6px 2px 2px;
}
.k-form-legend {
  text-align: left !important;
}
